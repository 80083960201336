@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.session {
  @include sessionTemplate;
  .content {
    h1 {
      margin-bottom: 20px;
    }
    .field {
      max-width: 430px;
      width: 100%;
      margin-bottom: 10px;
    }
    .valid {
      margin-top: 20px;
      width: auto;
      padding: 0px 20px;
    }
    .error {
      margin: 10px 0px 0px 0px;
      background-color: rgba($warn,0.2);
      padding: 6px 15px;
      border-radius: 30px;
      color: $warn;
    }
  }
}
