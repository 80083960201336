@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  position: fixed;
  top: 0px;
  height: 100svh;
  width: 100vw;
  background-color: $white;
  z-index: 100;
  transform: translateY(30px);
  opacity: 0;
  @include transition;
  pointer-events: none;
  @media only screen and (min-width: $md-screen) {
    display: none;
  }
  &.open {
    transform: translateY(0px);
    opacity: 1;
    pointer-events: all;
  }
  h3 {
    margin: 10px 20px;
    text-align: center;
    @include poppinsBold;
    font-size: 14px;
    color: $secondary-color;
  }
  .content {
    width: 100%;
    max-height: calc(100vh - 90px);
    padding: 0px 10px 40px 10px;
    overflow: auto;
    img {
      width: 100%;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 5px;
      &.xs {
        font-size: 14px;
      }
      &.md {
        font-size: 16px;
      }
      &.xl {
        font-size: 18px;
      }
    }
  }
  .close {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50px;
    background-color: $secondary-color;
    border: 0px;
    color: $white;
    @include poppinsBold;
    font-size: 14px;
  }
}