@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    @include globalPage;
  }
  .btn {
    @include btn;
  }
}