@import '../../styles/_variables.scss';

.question {
  position: relative;
  border-radius: 7px;
  border: 1px #E3E3E3;
  background: #FBFBFB;
  padding: $margin-sm;
  margin-bottom: $margin-md;

  &.loading >div:not(.loader) {
    opacity: 0.5;
    pointer-events: none;
  }

  .loader {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

}

.question .header {
  display: flex;
  justify-content: space-between;

  >div:first-child {
    display: flex;
    .icon {
      margin-top: 2px;
      margin-right: 4px;
      width: 14px;
      height: 14px;
      border-radius: 15px;
    }
    .generalType {
      color: $primary-color;
      margin-left: 5px;
    }
  }
  .options {
    display: flex;
    button {
      padding: 0px 10px;
      display: flex;
      align-items: center;
    }
  
    >div:not(:last-child),
    >button:not(:last-child) {
      margin-right: $margin-xs;
    }
  }
  
  .refresh {
    padding: 5px;
    height: auto;
  }
}

.question .questionPictures {
  display: flex;
  justify-content: space-around;
  .picture {
    position: relative;
    width: 33%;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
    margin: $margin-xs;
    object-position: center;

    button {
      position: absolute;
      top: $margin-xs;
      right: $margin-xs;
      z-index: 1;
      padding: $margin-xs;
      height: auto;
      color: $text-color;
      border: none;
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.question > .text {
  margin-top: $margin-sm;
}


.question > .options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;

  .option {
    border-radius: 6px;
    border: solid 1px #CBCBCB;
    background: #FFF;
    p {
      margin: 0;
      text-align: center;
      padding: $margin-xs;
    }

    .pictures {
      display: flex;
      justify-content: space-around;
      .picture {
        position: relative;
        width: 33%;
        height: 80px;
        border-radius: 4px;
        overflow: hidden;
        margin: $margin-xs;
        object-position: center;

        button {
          position: absolute;
          top: $margin-xs;
          right: $margin-xs;
          z-index: 1;
          padding: $margin-xs;
          color: $text-color;
          border: none;
          background-color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }

  }
}