@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 50px 10px;
  &.disabled {
    pointer-events: none;
  }
  @media only screen and (min-width: $md-screen) {
    align-items: center;
    width: 400px;
    padding: 10px 20px;
  }
  .field {
    margin-bottom: 15px;
    width: 100%;
  }
  .correction {
    text-align: right;
    color: $success;
    margin: 6px 0px 0px 0px;
  }
  &.xs {
    label {
      font-size: 14px;
    }
    input {
      font-size: 14px;
    }
    .correction {
      font-size: 14px;
    }
  }
  &.md {
    label {
      font-size: 16px;
    }
    input {
      font-size: 16px;
    }
    .correction {
      font-size: 16px;
    }
  }
  &.xl {
    label {
      font-size: 18px;
    }
    input {
      font-size: 18px;
    }
    .correction {
      font-size: 18px;
    }
  }
}