@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.desktop {
  height: 100%;
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
  }
  .header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0px;
      @include bold;
      font-size: 24px;
      line-height: 24px;
    }
    .actions {
      display: flex;
      align-items: center;
      .search {
        margin-right: 10px;
      }
      .create {
        @include btn;
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }
  .filters {
    padding: 0px 30px;
    display: flex;
    .filter {
      margin-right: 8px;
    }
  }
  .content {
    margin: 20px 30px;
    background-color: $white;
    border-radius: 10px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    .row {
      width: 100%;
      display: flex;
      padding: 6px 10px;
      text-decoration: none;
      @include transition;
      &:not(.label) {
        cursor: pointer;
        &:hover {
          background-color: rgba($purple, 0.05);
          .status {
            .dropdown {
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
      .col {
        width: calc(100% / 6);
        padding: 4px 8px;
        display: flex;
        align-items: center;
        position: relative;
        .picture {
          width: 46px;
          height: 46px;
          overflow: hidden;
          border-radius: 5px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        &.sort {
          cursor: pointer;
        }
        &.desc {
          .icon {
            transform: rotate(180deg)
          }
        }
        p {
          margin-bottom: 0px;
          font-size: 14px;
          &.role {
            border: 1px solid #EAE8E3;
            padding: 3px 10px;
            border-radius: 30px;
            font-size: 12px;
          }
        }
        .tags {
          display: flex;
          p {
            font-size: 12px;
            margin: 0px;
            padding: 4px 8px;
            border-radius: 7px;
            margin-right: 6px;
          }
        }
      }
      &.label {
        border-bottom: 1px solid #EAE8E3;
        .col {
          p {
            font-size: 12px;
            color: $text-light;
          }
        }
      }
    }
    .list {
      &.learner {
        padding-bottom: 0px;
      }
    }
  }
  .grid {
    padding: 20px 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @media screen and (min-width: 1300px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}