@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  width: 100%;
  input {
    display: none;
  }
  p {
    color: $warn;
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
  }
  label {
    width: 100%;
  }
  span {
    @include semiBold;
    color: rgba($black, 0.5)
  }
  .btn {
    display: block;
    @include btn;
    border: 1px solid $white;
  }
  .drag {
    padding: 120px 0px;
  }
  &.cover {
    width: 100%;
    height: 100%;
    label {
      @include semiBold;
      cursor: pointer;
      width: 100%;
      height: 100%;
      @include flex-center;
      flex-direction: column;
      h4  {
        margin: 0px;
        color: $black;
        text-decoration: underline;
      }
      span {
        @include regular;
        font-style: italic;
      }
    }
  }
}

