@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.mobile {
  height: 100%;
  @media screen and (min-width: $lg-screen) {
    display: none;
  }
  .btn {
    @include btn;
    margin: 20px 20px;
  }
  .list {
    overflow-y: auto;
    padding: 0px 20px 20px 20px;
    .card {
      min-height: 83px;
      display: block;
      text-decoration: none;
      padding: 15px;
      border-radius: 12px;
      background: $white;
      margin-bottom: 20px;
      box-shadow: 0px 5.453px 32.717px 0px rgba(178, 178, 178, 0.20);
      h4 {
        margin-bottom: 10px;
        font-size: 16px;
        span {
          text-transform: uppercase;
        }
      }
      .row {
        display: flex;
        justify-content: space-between;
        .role {
          border: 1px solid #EAE8E3;
          padding: 3px 10px;
          border-radius: 30px;
          font-size: 12px;
        }
        .state {
          display: flex;
          align-items: center;
          transform: translateY(5px);
          p {
            margin: 0px;
          }
          span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            margin-right: 5px;
          }
        }
        .tags {
          display: flex;
          p {
            font-size: 12px;
            margin: 0px;
            padding: 4px 8px;
            border-radius: 7px;
            margin-right: 6px;
          }
          &:last-child {
            p {
              margin-right: 0px;
              margin-left: 6px;
            } 
          }
        }
      }
    }
  }
}