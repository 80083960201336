@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.name {
  h1 {
    @include bold;
    font-size: 24px;
    margin: 0px;
  }
  p {
    color: $text-light;
  }
}

.form {
  @media screen and (min-width: $lg-screen) {
    width: 60%;
    display: flex;
    flex-direction: column-reverse;
    section {
      margin-bottom: 20px;
    }
    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .field {
        width: calc(50% - 10px);
      }
    }
  }
  .row {
    .field {
      margin-bottom: 10px;
    }
  }
  label {
    color: #6F6F6F;
  }
  .picture-container {
    width: 182px;
    .picture {
      margin-top: 6px;
      @include profilePicture;
    }
  }
}