@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  position: fixed;
  top: 0px;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  transform: scale(0.9);
  opacity: 0;
  @include transition;
  display: none;
  pointer-events: none;
  @media only screen and (min-width: $md-screen) {
    @include flex-center;
  }
  &.open {
    transform: scale(1);
    opacity: 1;
    pointer-events: all;
    &:hover {
      cursor: url('./images/cursor-close.svg'), auto;
    }
  }
  .content {
    position: relative;
    width: calc(100vw - 300px);
    margin: 0px auto 0px auto;
    height: calc(100% - 140px);
    border-radius: 20px;
    background-color: $black;
    @include transition;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      &:hover {
        cursor: url('./images/cursor-close.svg'), auto;
      }
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 50px;
    height: 50px;
    border: none;
    @include flex-center;
    background-color: transparent;
    img {
      width: 30px;
      height: auto;
    }
  }
}