@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.edit {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  button {
    @include btn;
  }
  .header {
    width: 100%;
    padding-left: $margin-sm;
    padding-top: 15px;
    display: none;
    @media screen and (min-width: $xl-screen) {
      padding-left: 0;
      display: block;
    }

    a {
      margin-top: 10px;
      margin-left: 25px;
      text-decoration: none;
      color: $text-light;
      cursor: pointer;
      svg {
        position: relative;
        margin-right: 5px;
        top: 2px;
      }
    }
  }
  .page {
    @include globalPage;
    overflow-x: hidden;
    padding-bottom: 120px;
    @media screen and (min-width: $xl-screen) {
      padding-bottom: 0px;
    }
  }
}