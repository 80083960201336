@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  width: 100%;
  @media only screen and (min-width: $md-screen) {
    height: 80%;
  }
  z-index: 2;
  &.disabled {
    pointer-events: none;
  }
  .list {
    padding: 10px 20px;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
    }
    .option {
      border-radius: 6px;
      background: #FFF;
      border: 1px solid #EAE8E3;
      // box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.40);
      margin-bottom: 10px;
      padding: 10px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.dragging {
        box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.40);
        border: 2px solid $primary-color;
      }
      @media only screen and (max-width: $md-screen) {
        transform: none !important;
      }
      p {
        margin: 0px 0px 0px 15px;
        width: 50%;
        font-size: 12px;
        @include poppinsMedium;
      }
      @media only screen and (min-width: $md-screen) {
        transform: none;
        width: 170px;
        margin: 0px 12px;
        display: flex; 
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        p {
          text-align: center;
          margin: 0px 0px 10px 0px;
          width: 100%;
          @include poppinsBold;
        }
      }
      @media only screen and (min-width: 1000px) {
        width: 200px;
        margin: 0px 15px;
      }
      @media only screen and (min-width: 1100px) {
        width: 230px;
        margin: 0px 20px;
      }
      @media only screen and (min-width: 1200px) {
        width: 240px;
      }
      @media only screen and (min-width: 1300px) {
        width: 280px;
        margin: 0px 20px;
      }
      &.text {
        height: 100px;
        p {
          max-width: 80%;
          text-align: center;
          margin: 0px;
        }
        @media only screen and (min-width: $md-screen) {
          flex-direction: column;
          align-items: center;
        }
      }
      &:not(.text) {
        .option-pictures {
          width: 100%;
        }
      }
      &.empty {
        background:#f3f3f3;
        pointer-events: none;
        border: none;
        box-shadow: none;
        min-height: 60px;
        @media only screen and (min-width: $md-screen) {
          min-height: 60px;
        }
        @media only screen and (min-width: $lg-screen) {
          min-height: 82px;
        }
        &.QRA_R_NAMES_PICTURES {
          @media only screen and (min-width: $md-screen) {
            min-height: 82px;
          }
          @media only screen and (min-width: 1200px) {
            min-height: 100px;
          }
        }
        &.QRA_C_CHARACTERS {
          @media only screen and (min-width: $md-screen) {
            min-height: 128px;
          }
        }
        &.QRA_R_TAXONOMY {
          @media only screen and (min-width: $md-screen) {
            min-height: 125px;
          }
        }
      }
      &.response {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding: 0px;
        border-radius: 6px;
        background-color: #f3f3f3;
        border: none;
        box-shadow: none;
        p {
          margin: 0px 0px 0px 20px;
          width: 40%;
          font-size: 12px;
        }
        &.wrong {
          .input {
            &.active {
              border: 2px solid $warn;
            }
          }
        }
        &.correct {
          .input {
            &.active {
              border: 2px solid $success;
            }
          }
        }
        &.dragging {
          .input {
            border: 2px dashed $primary-color;
          }
        }
        @media only screen and (min-width: $md-screen) {
          background-color: transparent;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            margin: 0px 0px 0px 0px;
            width: auto;
            font-size: 12px;
            background-color: $black;
            padding: 5px 10px;
            border-radius: 5px;
            color: $white;
          }
        }

        @media only screen and (min-width: $md-screen) {
          &.QRA_R_NAMES_PICTURES {
            .input {
              min-height: 80px;
            }
          }
          &.QRA_C_CHARACTERS {
            .input {
              min-height: 128px;
            }
          }
          &.QRA_R_TAXONOMY {
            .input {
              min-height: 125px;
            }
          }
        }

        @media only screen and (min-width: $xl-screen) {
          &.QRA_R_NAMES_PICTURES {
            .input {
              min-height: 100px;
            }
          }
        }

        &.hover {
          p {
            background-color: $purple;
          }
          .input {
            background-color: rgba($purple, 0.2);
          }
        }

        .input {
          width: calc(60%);
          border-radius: 5px;
          margin: 10px;
          min-height: 40px;
          @include flex-center;
          @include transition;
          border: 2px dashed #cac9c9;
          box-shadow: none;
          @media only screen and (min-width: $md-screen) {
            width: calc(100%);
            p {
              padding: 5px 20px;
            }
            @media only screen and (min-width: $md-screen) {
              min-height: 60px;
            }
            @media only screen and (min-width: $lg-screen) {
              min-height: 82px;
            }
          }
          p {
            padding: 5px 20px;
            font-size: 12px;
          }
          &.active {
            background-color: $white;
            border: 1px solid #EAE8E3;
            box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.4);
            display: flex;
            justify-content: space-between;
            padding: 8px 8px;
            p {
              padding: 0px;
              text-align: center;
              width: 100%;
              margin: 0px;
            }
            .option-pictures {
              display: none;
            }
            &.pictures-active {
              .option-pictures {
                display: flex;
                width: 100%;
              }
            }
            @media only screen and (min-width: $md-screen) {
              flex-direction: column;
              text-align: center;
              justify-content: space-between; 
              align-items: center;
              padding: 10px 8px 8px 8px;
              .option-pictures {
                display: flex;
              }
              p {
                text-align: center;
                margin: 0px 0px 10px 0px;
                padding: 0px;
                background-color: transparent;
                color: $black;
                width: 100%;
                @include poppinsBold;
              }
            }
            cursor: pointer;
          }
          &.pictures-active {
            background-color: transparent;
            cursor: pointer;
            p {
              color: $black;
            };
          }
        }
      } 
    }
  }
}

.option-pictures {
  width: 50%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  @media only screen and (min-width: $md-screen) {
    width: 100%;
  }
  &.row-1 {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    img {
      height: 50px;
      border-radius: 4px;
      width: 100px;
      @media only screen and (min-width: $md-screen) {
        height: 78px;
        width: 100%;
        border-radius: 10px;
        justify-content: center;
      }
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    img {
      height: 100px;
      border-radius: 10px;
      width: calc(50% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-3 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 85px;
    @media only screen and (min-width: $md-screen) {
      height: 60px;
    }
    @media only screen and (min-width: $xl-screen) {
      height: 85px;
      display: flex;
    }
    img {
      height: 100%;
      border-radius: 10px;
      width: calc(33% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}