@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.list-tags{
  width: 100%;
  label {
    @include label;
  }

  &.inline{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    label{
      margin-right: 10px;
    }
  }

  &:not(.inline){
    label+ul{
      margin-top: $margin-sm;
    }
  }

  ul{
    gap: $margin-sm;
    width: 100%;
    padding: 0;
    margin: 0;
    @media screen and (min-width: $sm-screen) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
    li{
      list-style: none;
      margin-bottom: 10px;
      @media screen and (min-width: $sm-screen) {
        margin-bottom: 0px;
      }
      button {
        font-family: 'NunitoSans-Bold';
        width: 100%;
        border: none;
        color: $text-light;
        background-color: transparent;
        border-radius: $radius;
        padding: 10px;
        &.selected {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:not(.selected) {
          background-color: transparent;
          color: $text-light;
          border: 1px solid $primary-color;
          outline: none;
        }
      }
    }
  }
}
