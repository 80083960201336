@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.card {
  width: 100%;
  border-radius: 10px;
  background-color: $white;
  text-decoration: none;
  position: relative;
  .status {
    position: absolute;
    min-width: 77px;
    right: 0px;
    top: 0px;
    padding: 6px 10px;
    border-radius: 0px 10px 0px 10px;
    background-color: #FF845E;
    &.valid {
      background-color: #368F65;
    }
    p {
      margin: 0px;
      color: $white;
      text-align: center;
    }
  } 
  .picture {
    width: 100%;
    height: 141px;
    @include flex-center;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .infos {
    padding: 15px 15px;
    width: 100%;
    h4 {
      font-size: 16px;
      margin-bottom: 0px;
    }
    .progress {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 4px 0px 8px 0px;
      width: 100%;
      p {
        color: rgba($black, 0.3);
        margin: 0px;
      }
      .bar-container {
        height: 10px;
        width: calc(100% - 70px);
        border-radius: 5px;
        background: #F2F2F2;  
        .bar {
          height: 100%;
          background-color: #368F65;
          border-radius: 5px;
          @include transition;
        } 
      }
    }
  }
}