@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    @include globalPage;
    header {
      padding: 20px 30px 20px 30px;
    }
    .filters {
      display: flex;
      padding: 10px 20px;
      @media only screen and (min-width: $lg-screen) {
        padding: 0px 30px;
      }
      .filter {
        display: none;
        margin-right: 10px;
        @media only screen and (min-width: $sm-screen) {
          display: block;
        }
      }
    }
    .grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      max-height: calc(100vh - 110px);
      padding-bottom: 20px;
      overflow-y: auto;
      padding: 0px 20px;
      @include scrollbar;
      @media only screen and (min-width: $sm-screen) {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      @media only screen and (min-width: $lg-screen) {
        padding: 10px 30px;
        grid-template-columns: repeat(3, 1fr);
      }
      @media only screen and (min-width: $xl-screen) {
        grid-template-columns: repeat(4, 1fr);
      }
      .card {
        display: block;
        text-decoration: none;
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        background-color: $white;
        margin-bottom: 20px;
        @media only screen and (min-width: $sm-screen) {
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: flex-start;
          background-color: transparent;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
        .cover {
          height: 138px;
          width: 95px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #efeeee;
          @media only screen and (min-width: $sm-screen) {
            width: 100%;
            border-radius: 10px;
          }            
          img {
            height: 100%;
            @include transition;
            object-fit: cover;
            @media only screen and (min-width: $sm-screen) {
              height: auto;
              width: 100%;
            }
          }
        }
        .infos {
          width: calc(100% - 95px);
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-end;
          padding: 10px 10px;
          @media only screen and (min-width: $sm-screen) {
            padding: 0px;
            width: 100%;
            flex-direction: column;
          }
          .tags {
            display: flex;
            flex-wrap: wrap;
            @media only screen and (min-width: $sm-screen) {
              margin: 5px 0px;
            }
            p {
              padding: 3px 10px;
              margin: 5px 5px 5px 0px ;
              border-radius: 30px;
              font-size: 12px;
              background-color: $primary-color;
              color: $white;
            }
          }
          h3 {
            font-size: 15px;
            margin-bottom: 0px;
            @include bold;
            @media only screen and (min-width: $sm-screen) {
              font-size: 18px;
            }
          }
          p {
            font-size: 12px;
            color: $text-light;
            display: -webkit-box;         
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5;
            max-height: calc(1.5em * 3);
            margin-bottom: 0px;
            &.creator {
              margin-top: 0px;
              margin-bottom: 0px;
              color: $primary-color;
              display: none;
            }
          }
        }
      }
    }
  }
  .btn {
    @include btn;
  }
}