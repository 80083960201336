@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.quiz {
  position: relative;
  width: 100%;
  height: 100vh;
  .submit {
    @include btn;
  }
  .page {
    @include globalPage;
    overflow-y: auto;
    padding-bottom: 40px;
    @media screen and (min-width: $lg-screen) {
      padding-bottom: 0px;
    }
    .header {
      padding: 20px 30px;
      justify-content: space-between;
      align-items: center;
      display: none;
      .create {
        @include btn;
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          font-size: 18px;
          margin-right: 10px;
        }
      }
      @media screen and (min-width: $lg-screen) {
        display: flex;
      }
      h1 {
        margin: 0px;
        @include bold;
        font-size: 24px;
        line-height: 24px;
      }
      .actions {
        display: flex;
        align-items: center;
        .search {
          margin-right: 10px;
        }
        .btn {
          @include btn;
        }
      }
    }
    .content {
      padding: 10px 20px;
      @media screen and (min-width: $lg-screen) {
        padding: 0px 30px;
      }
      .filters {
        margin-bottom: 20px;
      }
      section {
        margin-bottom: 40px;
        h2 {
          @include bold;
          font-size: 18px;
          color: #6F6F6F;
          margin-bottom: 30px;
          margin-bottom: 20px;
        }
      }
    }
    .loading {
      width: 100%;
      height: 80vh;
      @include flex-center;
    }
  }
  .btn {
    @include btn;
  }
}