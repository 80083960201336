@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.reset-password {
  @include authTemplate;
  .content {
    .form {
      h3 {
        display: block;
        font-size: 22px;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}