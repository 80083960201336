@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.form {
  margin-top: 10px;
  padding: 10px 20px 80px 20px;
  @media screen and (min-width: $lg-screen) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0px;
  }
  .col {
    width: 100%;
    @media screen and (min-width: $lg-screen) {
      display: flex;
      flex-direction: column;
      width: 340px;
      &:first-child {
        width: calc(100% - 360px);
        background-color: #FFFFFF;
        border-radius: 10px;
        padding: 20px;
      }
      .tags {
        background-color: #FFFFFF;
        padding: 20px;
        border-radius: 10px;
      }
    }
    .submit {
      position: fixed;
      bottom: 20px;
      @include btn;
      width: calc(100% - 40px);
      z-index: 10;
      @media screen and (min-width: $lg-screen) {
        position: relative;
        width: calc(100%);
        bottom: 0px;
      }
    }
    .creator {
      margin: 0px;
      margin-top: 15px;
      font-size: 12px;
      color: $text-light;
    }
    .name {
      max-width: 420px;
      input {
        background-color: transparent;
      }
    }
    .tags {
      margin-top: 15px;
      min-height: auto;
      @media screen and (min-width: $xl-screen) {
        padding-bottom: 5px;
      }
    }
    section {
      margin-top: 20px;
      width: 100%;
      .row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        @media screen and (min-width: $md-screen) {
          flex-direction: row;
          .search {
            width: 50%;
            margin-bottom: 0px;
            max-width: 300px;
            input {
              width: 100%;

            }
          }
          .btn {
            margin-left: 15px;
            max-width: 250px;
            margin-top: 0px;
          }
        }
        .search {
          border: 1px solid $border-grey;
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 10px;
          input {
            width: calc(100% - 30px);
            border-radius: 10px;
            border: none;
            padding: 10px 15px;
            background-color: transparent;
            &:focus {
              outline: none;
            }
          }
        }
        .btn {
          @include btn;
          width: 100%;
          margin-top: 10px;
          @media screen and (min-width: $md-screen) {
            margin-top: 0px;
          }
        }
      }
      .message {
        span {
          color: $primary-color;
          text-decoration: underline;
        }
      }
      h2 {
        font-size: 14px;
        @include bold;
      }
      .list {
        width: 100%;
        margin-bottom: 30px;
        margin-bottom: 30px;
        border-radius: 10px;
        overflow: hidden;
        &:first-child {
          .item {
            background-color: rgba(#368F65, 0.2);
          }
        }
        .item {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: center;
          min-height: 45px;
          border-bottom: 1px solid $border-grey;
          padding: 0px 15px;
          &.add {
            cursor: pointer;
            background-color: rgba(#368F65, 0.2);
            button {
              color: #368F65;
            }
            &:not(:last-child) {
              border-bottom: 1px solid rgba(#368F65, 0.2);
            }
            &:nth-child(odd) {
              background-color: rgba(#368F65, 0.2);
            }
          }
          &:nth-child(odd) {
            background-color: rgba($border-grey, 0.3);
          }
          p {
            margin: 0px;
            font-style: italic;
            cursor: pointer;
          }
          button {
            padding: 0px;
            background-color: transparent;
            border: none;
            height: auto;
            font-size: 12px;
            color: $primary-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
