@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.counter {
  width: 100%;
  height: 100%;
  border-radius: 11px;
  background: $white;
  padding: 20px;
  .title {
    display: flex;
    align-items: center;
    .icon {
      width: 35px;
      height: 35px;
      border-radius: 6px;
      margin-right: 10px;
      @include flex-center;
    }
    p {
      margin: 0px;
    }
  }
  h2 {
    @include bold;
    font-size: 44px;
    margin: 10px 0px 0px 0px;
  }
}