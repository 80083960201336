@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.options {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin-top: 10px;
  &.disabled {
    pointer-events: none;
  }
  @media only screen and (min-width: $md-screen) {
    align-items: center;
  }
  @media only screen and (min-width: $md-screen) {
    width: 450px;
  }
  .select {
    border-radius: 8px;
    border: 1px solid #D4D4D4;
    background-color: #FBFAF9;
    margin-bottom: 10px;
    width: 100%;
    .label {
      cursor: pointer;
      width: 100%;
      padding: 20px 20px;
      border-radius: 10px;
      background-color: transparent;
      border: 0px solid transparent;
      display: flex;
      align-items: center;
      justify-content: space-between;
      p {
        margin: 0px;
        padding: 0px;
        text-align: left;
        padding: 0px;
        @include regular;
        color: $black;
        font-size: 14px;
        white-space: pre-line;
        &.response {
          text-align: right;
          @include bold;
          color: $primary-color;
          max-width: 50%;
          white-space: pre-line;
        }
      }
    }
    .items {
      padding: 0px 10px;
      .label {
        border: 1px solid #D4D4D4;
        background-color: $white;
        padding: 15px 20px;
        margin-bottom: 10px;
        @include transition;
        &:hover {
          border: 1px solid $primary-color;
          background-color: rgba($primary-color, 0.1);
        }
        &.active {
          border: 1px solid $primary-color;
          background-color: $primary-color;
          p {
            color: $white;
          }
        }
        p {
          width: 100%;
          text-align: center;
        }
      }
    }
  }

}