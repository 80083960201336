@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.organistions { 
  @include authTemplate;
  .content {
    .form {
      h3 {
        display: block;
        font-size: 18px;
        line-height: 18px;
        text-align: left;
        margin-bottom: 15px;
      }
      .list {
        width: 100%;
        button {
          width: 100%;
          border-radius: 10px;
          background-color: transparent;
          border: 1px solid #EAE8E3;
          color: $black;
          margin: 0px;
          margin-bottom: 15px;
          @include regular;
          &:hover {
            background-color: $purple;
            color: $white;
          }
        }
      }
    }
  }
}
