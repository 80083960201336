@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.desktop {
  height: 100%;
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
  }
  .header {
    padding: 20px 30px 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0px;
      @include bold;
      font-size: 24px;
      line-height: 24px;
    }
    .actions {
      display: flex;
      align-items: center;
      .search {
        margin-right: 10px;
      }
      .btn {
        @include btn;
      }
    }
  }
  .content {
    margin: 20px 30px;
    background-color: $white;
    border-radius: 10px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    .row {
      width: 100%;
      display: flex;
      padding: 6px 10px;
      text-decoration: none;
      @include transition;
      &:not(.label) {
        transition: margin 0.2s ease;
        cursor: grab;
        &.dragging {
          background-color: #f0f8ff;
          opacity: 0.8;
        }
        &.hovered {
          background-color: #eef7ff;
          margin-top: 20px; /* Adds space above the hovered row */
          transition: margin 0.2s ease;
        }
      }
      .col {
        width: calc(85% / 3);
        padding: 4px 8px;
        display: flex;
        align-items: center;
        position: relative;
        &.sort {
          cursor: pointer;
        }
        &.desc {
          .icon {
            transform: rotate(180deg)
          }
        }
        .icon {
          position: absolute;
          @include flex-center;
          color: $text-light;
          left: -10px;
        }
        &:last-child {
          width: 15%;
        }
        p {
          margin-bottom: 0px;
          font-size: 14px;
          &.order {
            @include flex-center;
            @include bold;
            background-color: #EDEEF5;
            width: 24px;
            height: 24px;
            border-radius: 30px;
            font-size: 12px;
          } 
          &.role {
            border: 1px solid #EAE8E3;
            padding: 3px 10px;
            border-radius: 30px;
            font-size: 12px;
          }
        }
        .condition {
          display: flex;
          align-items: center;
          border: 1px solid #EAE8E3;
          height: 28px;
          padding: 0px 3px;
          border-radius: 10px;
          &:hover {
            input {
              background-color: #f0f8ff;
            }
          }
          input {
            width: 28px;
            background-color: transparent;
            border: none;
            text-align: center;
            border-radius: 10px;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            -moz-appearance: textfield;
          }
          p {
            @include bold;
            width: 28px;
            text-align: center;
          }
        }
        .tags {
          display: flex;
          p {
            font-size: 12px;
            margin: 0px;
            padding: 4px 8px;
            border-radius: 7px;
            margin-right: 6px;
          }
        }
      }
      &.label {
        border-bottom: 1px solid #EAE8E3;
        .col {
          p {
            font-size: 12px;
            color: $text-light;
          }
        }
      }
    }
    .list {
      // overflow-y: auto;
      // max-height: calc(100vh - 210px);
    }
  }
}