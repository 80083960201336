@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.stars {
  path {
    @include transition;
  }
}

.container {
  position: relative;
  width: 100vw;
  max-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 0px 0px 60px 0px;
  overflow-y: auto;
  overflow-x: hidden;
  @media only screen and (min-width: $md-screen) {
    width: calc(100vw - 300px);
    padding: 30px 20px;
    margin: 70px auto 0px auto;
    height: calc(100% - 140px);
    border-radius: 20px;
    background-color: $white;
  }
  @media only screen and (min-width: $xl-screen) {
    padding: 0px;
  }
  .bg-pictures-pairing {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1;
    height: 100%;
    width: 50%;
    position: absolute;
    background-color: #FBFAF9;
    display: none;
    @media only screen and (min-width: 1100px) {
      display: block;
    }
  }
  .bg-pairing {
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 1;
    height: 50%;
    width: 100%;
    position: absolute;
    background-color: #FBFAF9;
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
    }
  }
  .pictures {
    display: flex;
    flex-wrap: wrap;
    height: 25vh;
    margin: 10px 0px;
    &.col-1 {
      margin: 10px 20px;
      @media only screen and (min-width: $md-screen) {
        margin: 0px 10px  30px 10px;
      }
      @media only screen and (min-width: $xl-screen) {
        margin: 20px;
        width: calc(50% - 20px);
      }
      .img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &.col-2 {
      margin: 10px 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
      }
      @media only screen and (min-width: $xl-screen) {
        flex-direction: column;
      }
      .img {
        height: 100%;
        width: calc(50% - 10px);
        border-radius: 8px;
        @media only screen and (min-width: $xl-screen) {
          width: 100%;
          height: calc(50% - 10px);
        }
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &.col-3 {
      margin: 10px 20px;
      display: flex;
      flex-wrap: wrap;
      @media only screen and (min-width: $md-screen) {
        margin: 20px;
      }
      .img {
        width: 100%;
        height: calc(50% - 10px);
        border-radius: 8px;
        margin-bottom: 10px;
        img {
          border-radius: 8px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        &:nth-child(2) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          margin-right: 10px;
        }
        &:not(:first-child) {
          width: calc(50% - 5px);
        }
      }
    }
    @media only screen and (min-width: $xl-screen) {
      position: absolute;
      margin: 0px;
      height: calc(100% - 40px);
      width: 50%;
    }
  }
  .question {
    @media only screen and (min-width: $md-screen) {
      @include flex-center;
      flex-direction: column;
      &:not(.withPictures) {
        height: 100%;
        padding: 0px;
      }
    }
    @media only screen and (min-width: $xl-screen) {
      &:not(.withPictures) {
        height: 100%;
        padding: 0px 50px;
      }
    }
    // &.disabled {
    //   pointer-events: none;   
    // }
    .senctence-block {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      .nav {
        min-width: 40px;
        @media only screen and (min-width: $md-screen) {
          display: none;
        }
        button {
          width: 100%;
          display: flex;
          background-color: transparent;
          border: none;
          @include flex-center;
          font-size: 25px;
        }
      }
      .sentence {
        padding: 20px 0px 20px 0px;
        margin: 0px;
        @include poppinsBold;
        text-align: center;
        &.picture-pairing {
          @media only screen and (min-width: 1100px) {
            position: absolute;
            width: 50%;
            left: 0px;
            top: 30px;
            padding: 0px 30px;
          }
        }
        &.pairing {
          @media only screen and (min-width: $md-screen) {
            position: absolute;
            width: 100%;
            max-width: 100%;
            left: 0px;
            top: 30px;
            padding: 0px 30px;
          }
        }
        &.xs {
          font-size: 14px;
          @media only screen and (min-width: $md-screen) {
            font-size: 18px;
          }
        }
        &.md {
          font-size: 16px;
          @media only screen and (min-width: $md-screen) {
            font-size: 20px;
          }
        }
        &.xl {
          font-size: 18px;
          @media only screen and (min-width: $md-screen) {
            font-size: 22px;
          }
        }
        @media only screen and (min-width: $md-screen) {
          text-align: center;
          padding: 0px 20px 10px 20px;
        }
      }
    }
    @media only screen and (min-width: $md-screen) {
      &.onlyText {
        margin-top: 100px;
      }
      &.withPictures {
        .sentence {
          padding: 0px 20px 10px 20px;
        }
      }
    }
    @media only screen and (min-width: $xl-screen) {
      height: 100%;
      .sentence {
        max-width: 720px;
        padding: 20px 30px;
      }
      &.withPictures {
        position: absolute;
        margin-top: 0px;
        width: 50%;
        top: 0px;
        height: 100%;
        padding: 0px 60px 0px 40px;
        right: 0px;
        @include flex-center;
        flex-direction: column;
        .sentence {
          text-align: center;
      
        }
      }
    }
  }
}
.actions {
  position: fixed;
  height: 50px;
  bottom: 0px;
  width: 100vw;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  @include transition;
  @media only screen and (min-width: $md-screen) {
    justify-content: space-between;
    height: 64px;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    @include poppinsBold;
    color: $white;
    height: 100%;
    width: calc(80%);
    border: none;
    cursor: pointer;
    transition: width 0.5s ease;
    background-color: $primary-color;
    border-top-left-radius: 50px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    svg {
      font-size: 16px;
    }
    .stars {
      display: none;
    }
    @media only screen and (min-width: $md-screen) {
      img {
        display: block;
      }
      width: 200px;
      height: 60px;
      border-radius: 30px;
      box-shadow: 0px 0px 24px rgba(104, 118, 125, 0.25);
    }
  }
  &.disabled {
    .submit {
      pointer-events: none;
      background-color: #949494;
      @media only screen and (min-width: $md-screen) {
        background-color: $primary-color;
      }
    }   
  }
  &.submited {
    .submit {
      pointer-events: none;
      background-color: $primary-color;
      &.showResponse {
        pointer-events: all;
      }
      &.wrong {
        background-color: $warn;
      }
      &.correct {
        background-color: $success;
      }
      span {
        display: block;
        margin-left: 10px;
      }
      @media only screen and (min-width: $md-screen) {
        &.wrong {
          font-size: 20px;
        }
        &.correct {
          font-size: 20px;
        }
        span {
          display: none;
        }
      }
    }
  }
  @media only screen and (min-width: $md-screen) {
    position: absolute;
    width: calc(100vw - 300px);
    left: 150px;
    bottom: 35px;
    @include flex-center;
    background-color: transparent;
    .submit {
      background-color: $primary-color;
      border: 1px solid $white;
      font-size: 20px;
      @include transition;
      .stars {
        display: block;
        transform: translateX(-10px);
        path {
          @include transition;
          transition-delay: 0.15s;
          &:first-child {
            transition-delay: 0.25s;
          }
        }
      }
      &:hover {
        background-color: #353B6F;
      }
    }
    &.disabled {
      background-color: transparent;
      .submit {
        pointer-events: none;
        background-color: #949494;
      }
    }
    &.submited {
      background-color: transparent;
      .submit {
        width: 60px;
        @include flex-center;
        font-size: 25px;
        .stars {
          display: none;
        }
        &.wrong {
          width: auto;
          padding: 0px 25px;
        }
        &.correct {
          width: auto;
          padding: 0px 25px;
        }
      }
    }
  }
  .nav {
    width: 50px;
    @include flex-center;
    display: none;
    @media only screen and (min-width: $md-screen) {
      display: block;
      width: 82px;
      position: fixed;
      top: calc(50vh - 38px);
      cursor: pointer;
      &:first-child {
        left: 70px;
      }
      &:last-child {
        right: 50px;
      }
    }
    button {
      @include flex-center;
      @include transition;
      padding: 0px;
      width: 36px;
      height: 36px;
      background-color: transparent;
      cursor: pointer;
      border: 2px solid rgba($white, 0.2);
      border-radius: 30px;
      color: $white;
      font-size: 20px;
      @media only screen and (min-width: $md-screen) {
        width: 63px;
        height: 63px;
        border-radius: 41px;
        background-color: $purple;
        color: $white;
        font-size: 32px;
        box-shadow: 0px 0px 24px rgba(104, 118, 125, 0.25);
        &.active {
          background-color: $white;
          color: $purple;
        }
        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}