@import '../../../styles/_variables.scss';
@import '../../../styles/_mixins.scss';

.create {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .header {
    display: flex;
    width: 100%;
    padding: 20px;

    a {
      display: block;
      text-decoration: none;
      color: $text-light;
      svg {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }

    p {
      font-family: "NunitoSans-Bold";
      margin-left: $margin-xs;
      padding-left: $margin-xs;
      margin-bottom: 0;
      border-left: solid 1px $border-grey;
    }

  }
  .page {
    width: 100%;
    @include globalPage;
    @include page;
    padding: 10px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    padding-bottom: 120px;
    @media screen and (min-width: $lg-screen) {
      flex-direction: row;
      padding-bottom: 0px;
      padding: 20px 30px;
    }
    .form {
      padding: 0;
      width: 100%;
      @media screen and (min-width: $lg-screen) {
        width: calc(100% - 354px);
      }
    }
    
    .moreSettings {
      margin-bottom: 20px;
      width: 100%;
      margin-top: 20px;
      @media screen and (min-width: $lg-screen) {
        min-height: 100%;
        width: 354px;
        padding-left: $margin-sm;
        margin-top: 0px;
      }

      .errors {
        position: fixed;
        bottom: 80px;
        left: 20px;
        width: calc(100% - 40px);
        background-color: #fadad9;
        border-radius: 10px;
        padding: 6px 4px;
        text-align: center;
        @media screen and (min-width: $lg-screen) {
          position: relative;
          bottom: 0px;
          left: 0px;
          width: 100%;
          padding: 0px;
          background-color: transparent;
          text-align: left;
          margin-bottom: 20px;
        }
      }

      .save {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 60px;
        display: flex;
        z-index: 100;
        margin: 0px;
        border-radius: 10px 10px 0px 0px;
        align-items: center;
        @include bold;
        font-size: 18px;
        @media screen and (min-width: $lg-screen) {
          position: relative;
          height: auto;
          width: 100%;
          border-radius: 10px;
          margin-bottom: 15px;
          @include regular;
          font-size: 14px;
        }
        .dot {
          position: relative;
          transform: translate(5px, 2px);
          margin-left: 5px;
        }
        .dotLoader {
          @include dotLoader();
          background-color: rgba($white, 0.5);
        }
      }

      button {
        width: 100%;
        margin-bottom: $margin-sm;
        .dotLoader {
          @include dotLoader();
          background-color: rgba($white, 0.5);
        }
      }
    }
  }
}