@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.settings-mobile {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  z-index: 200;
  @include settings-box;
  transform: translateY(120%);
  @include transition;
  &.active {
    transform: translateY(0px);
  }
  @media only screen and (min-width: $md-screen) {
    display: none;
  }
  h4 {
    font-family: "Poppins-SemiBold";
    padding-bottom: 5px;
    border-bottom: 1px solid #EAE8E3; 
  }
  label {
    display: block;
    font-family: "Poppins-SemiBold";
    font-size: 12px;
  }
  .fonts {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    margin-bottom: 10px;
    @media only screen and (min-width: $sm-screen) {
      justify-content: flex-start;
    }
    button {
      background-color: transparent;
      border: none;
      height: 50px;
      border-radius: 10px;
      width: calc(33.33% - 10px);
      outline: 1px solid #EAE8E3;
      @media only screen and (min-width: $sm-screen) {
        width: 80px;
        margin-right: 20px;
      }
      &.active {
        outline: 2px solid $primary-color;
      }
      &.xs {
        svg {
          transform: scale(0.8);
        }
      }
      &.xl {
        svg {
          transform: scale(1.2);
        }
      }
    }
  }
  .sound {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    .check {
      border: 1px solid #EAE8E3;
      width: 20px;
      height: 20px;
      @include flex-center;
    }
    p {
      margin-left: 10px;
      display: block;
      font-family: "Poppins-SemiBold";
      font-size: 12px;
    }
  }
}

.settings-desktop {
  position: fixed;
  bottom: 70px;
  left: 90px;
  z-index: 200;
  display: none;
  @media only screen and (min-width: $md-screen) {
    display: block;
  }
  @media only screen and (min-width: 1100px) {
    left: 70px;
  }
  .options {
    position: absolute;
    bottom: 0px;
    transform: translateX(50px);
    pointer-events: none;
    opacity: 0;
    @include transition;
    &.active {
      transform: translateX(0px);
      pointer-events: all;
      opacity: 1;
    }
    button {
      width: 48px;
      height: 48px;
      @include flex-center;
      border-radius: 10px;
      margin-top: 10px;
      @media only screen and (min-width: 1100px) {
        width: 63px;
        height: 63px;
        margin-top: 15px;
        border-radius: 15px;
      }
      border: none;
      cursor: pointer;
      background-color: #FBFAF9;
    }
    &.font {
      background-color: #FBFAF9;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0px;
      width: 48px;
      @media only screen and (min-width: 1100px) {
        width: 63px;
        border-radius: 15px;
      }
      button {
        margin-top: 0px;
        width: 40px;
        height: 40px;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        &.active {
          border: 1px solid $primary-color;
          outline: 1px solid $primary-color;
        }
        @media only screen and (min-width: 1100px) {
          width: 55px;
          height: 55px;
          margin-top: 0px;
          border-radius: 15px;
        }
        border: 1px solid #EAE8E3;
        &.xs {
          svg {
            transform: scale(0.8);
          }
        }
        &.xl {
          svg {
            transform: scale(1.2);
          }
        }
      }
      .close {
        padding: 8px 0px 4px 0px;
        cursor: pointer;
      }
    }
  }
}