@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .submit {
    @include btn;
  }
  .page {
    @include globalPage;
    overflow-y: auto;
    padding-bottom: 40px;
    @media screen and (min-width: $lg-screen) {
      padding-bottom: 0px;
    }
    header {
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
    }
    .content {
      padding: 10px 20px;
      @media screen and (min-width: $lg-screen) {
        padding: 0px 30px;
      }
      h1 {
        font-size: 16px;
      }
      .description {
        font-size: 12px;
        max-width: 800px;
        margin-bottom: 20px;
        color: rgba($black, 0.5)
      }
      h2 {
        @include bold;
        color: rgba($black, 0.5);
        margin-bottom: 10px;
        font-size: 18px;
      }
      .list {
        margin-bottom: 20px;
      }
    }
  }
  .btn {
    @include btn;
  }
}