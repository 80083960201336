@mixin regular {
  font-family: "NunitoSans";
}

@mixin semiBold {
  font-family: "NunitoSans";
}

@mixin bold {
  font-family: "NunitoSans-Bold";
}

@mixin poppinsBold {
  font-family: "Poppins-SemiBold";
}

@mixin poppinsMedium {
  font-family: "Poppins-Medium";
}

@mixin poppinsRegular {
  font-family: "Poppins-Regular";
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($purple, 0.2);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin label {
  @include regular;
  font-size: 13px;
  line-height: 13px; 
  color: $text-color;
  white-space: nowrap;
  display: block;
  margin-left: 20px;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}


@mixin btn {
  @include semiBold;
  @include transition;
  align-items: center;
  justify-content: center;
  background-color: $primary-color;
  padding: 11px 20px;
  color: $white;
  border: solid 1px $primary-color;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  &.radius {
    border-radius: 50px;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.unActive {
    background-color: #EAEAEA;
    border: solid 1px #EAEAEA;
    color: $text-light;
    &:hover {
      background-color: $primary-color;
      color: $white;
      border: solid 1px $primary-color;
    }
  }
  &.add {
    display: flex;
    align-items: center;
    padding: 0px 14px;
    min-height: 44px;
    text-decoration: none;
    svg {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}

@mixin containerField {
  margin-bottom: 20px;
}

@mixin fontSize {
  &.xs {
    font-size: 14px;
  }
  &.md {
    font-size: 16px;
  }
  &.xl {
    font-size: 18px;
  }
}

@mixin settings-box {
  box-shadow: 0px -36.23px 22.638px rgba(0, 0, 0, 0.10);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #FBFAF9;
  border: 2px solid #D4D4D4;
  padding: 20px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin dotLoader($colorDot: $primary-color) {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $colorDot;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}


@mixin sessionTemplate {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/fond.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $purple;
  @include flex-center;
  .content {
    max-width: 638px;
    max-height: 617px;
    width: 50%;
    height: calc(70%);
    border-radius: 20px;
    background: $white;
    padding: 30px;
    @include flex-center;
    flex-direction: column;
    h1 {
      font-size: 18px;
      @include poppinsBold;
    }
    .valid {
      @include poppinsBold;
      color: $white;
      border: none;
      height: 60px;
      width: 200px;
      font-size: 20px;
      cursor: pointer;
      transition: width 0.5s ease;
      background-color: $primary-color;
      border-radius: 30px;
      @include transition;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin-right: 10px;
        margin-left: -10px;
        @include transition;
      }
      &:hover {
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@mixin authTemplate {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: $purple;
  @media only screen and (min-width: $lg-screen) {
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    background-color: $purple;
  }
  .picture {
    background-repeat: no-repeat;
    height: 35vh;
    width: 100%;
    @include flex-center();
    align-items: flex-end;
    @media only screen and (min-height: 750px) {
      height: 50vh;
    }
    @media only screen and (min-width: $lg-screen) {
      width: calc(50%);
      height: 100%;
      margin-left: 0px;
      margin-top: 0px;
      align-items: center;
    }
    .mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      overflow: hidden;
      @media only screen and (min-width: $lg-screen) {
        display: none;
      }
      img {
        float: right;
        height: 20vh;
        transform: translate(40px, 32px);
        @media only screen and (min-height: 750px) {
          height: 28vh;
        }
      }
      p, h2 {
        @include poppinsRegular;
        margin-left: 30px;
        padding-bottom: 10px;
        width: calc(100% - 30px);
        @media only screen and (min-width: $xs-screen) {
          margin-left: calc(((100% - 480px) / 2));
          padding-bottom: 10px;
          width: calc(100% - ((100% - 400px)) / 2);
        }
      }
      p {
        color: rgba($white,0.75);
        border-bottom: 2px solid rgba($white,0.65);
      }
      h2 {
        @include poppinsBold;
        color: $white;
        font-size: 22px;
        line-height: 24px;
        @media only screen and (min-width: $xs-screen) {
          font-size: 32px;
          line-height: 34px;
        }
      }
    }
    .desktop {
      display: none;
      position: relative;
      width: 100%;
      height: 100%;
      .logo {
        width: 100%;
        @include flex-center;
        img {
          width: 168px;
          margin: 0px auto;
        }
      }
      img {
        width: 65%;
      }
      @media only screen and (min-width: $lg-screen) {
        @include flex-center;
        flex-direction: column;
      }
    }
  }
  .content {
    width: 100%;
    height: 65vh;
    z-index: 1;
    overflow: auto;
    background-color: $white;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    display: flex;
    justify-content: center;
    @media only screen and (min-height: 750px) {
      height: 50vh;
    }
    @media only screen and (min-width: $lg-screen) {
      align-items: center;
      width: 50%;
      height: auto;
      background-color: $white;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      border-top-right-radius: 0px;
      height: 100%;
    } 
    .form {
      width: 100%;
      max-width: 450px;
      padding: $margin-lg;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @media only screen and (min-width: $lg-screen) {
        max-width: 500px;
      }
      h3 {
        display: none;
        text-align: left;
        @include poppinsBold;
        width: 100%;
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 30px;
        @media only screen and (min-width: $lg-screen) {
          display: block;
        }
      }
      button {
        @include btn;
        @include poppinsBold;
        background-color: $purple;
        border: 1px solid $purple;
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
      }
    }
  }
}

@mixin profilePicture {
  position: relative;
  background-color: #F3A39E;
  width: 182px;
  height: 182px;
  border-radius: 12px;
  @include flex-center;
  overflow: hidden;
  margin-bottom: 20px;
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .icon {
    position: absolute;
    @include flex-center;
    cursor: pointer;
    top: 6px;
    right: 6px;
    width: 42px;
    height: 42px;
    border-radius: 12px;
    background-color: $primary-color;
    img {
      transform: translate(-2px , -2px)
    }
  }
  p {
    @include bold;
    color: $white;
    font-size: 64px;
    margin: 0px;
    text-transform: uppercase;
  }
}

@mixin globalPage {
  position: absolute;
  top: 70px;
  height: calc(100% - 70px);
  width: 100%;
  @media screen and (min-width: $lg-screen) {
    height: 100%;
    top: 0px;
  }
  header {
    display: none;
    padding: 20px 30px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 84px;
    h1 {
      margin: 0px;
      @include bold;
      font-size: 24px;
      line-height: 24px;
    }
    @media screen and (min-width: $lg-screen) {
      display: flex;
    }
  }
}

@mixin page {
  padding: 30px;
  h3 {
    font-family: "NunitoSans-Bold";
    font-size: 14px;
  }
  button {
    @include btn;
  }
}

@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}