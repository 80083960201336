@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.formationDetail {
  position: relative;
  width: 100%;
  height: 100vh;
  .submit {
    @include btn;
  }
  .page {
    @include globalPage;
    overflow-y: auto;
    padding-bottom: 40px;
    @media screen and (min-width: $lg-screen) {
      padding-bottom: 0px;
    }
    header {
      justify-content: flex-start;
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
      .links {
        margin-left: 30px;
        button {
          background-color: transparent;
          border: transparent;
          @include bold;
          font-size: 18px;
          padding: 0px 20px;
          color: $text-light;
          cursor: pointer;
          &.active {
            color: $black;
          }
        }
      }
    }
    .content {
      padding: 0px;
    }
    .toggle {
      margin: auto;
      display: flex;
      justify-content: center;
      border-radius: 40px;
      background: #EAECFE;
      width: calc(100% - 40px);
      max-width: 300px;
      margin-top: 20px;
      button {
        width: 50%;
        display: block;
        @include bold;
        border: none;
        background-color: transparent;
        padding: 8px 10px;
        color:  #4C549E;
        @include transition;
        &.active {
          background-color: $white;
          border-radius: 40px;
          box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.20);
        }
      }
      @media screen and (min-width: $lg-screen) {
        display: none;
      } 
    }
  }

}