@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.desktop {
  height: 100%;
  display: none;
  @media screen and (min-width: $lg-screen) {
    display: block;
  }
  .header {
    padding: 20px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0px;
      @include bold;
      font-size: 24px;
      line-height: 24px;
    }
    .actions {
      display: flex;
      align-items: center;
      .search {
        margin-right: 10px;
      }
      .btn {
        @include btn;
      }
    }
  }
  .filters {
    padding: 0px 30px;
    display: flex;
    .filter {
      margin-right: 8px;
    }
  }
  .content {
    margin: 20px 30px;
    background-color: $white;
    border-radius: 10px;
    padding-bottom: 2px;
    display: flex;
    flex-direction: column;
    .row {
      width: 100%;
      display: flex;
      padding: 6px 10px;
      text-decoration: none;
      @include transition;
      &:not(.label) {
        cursor: pointer;
        &:hover {
          background-color: rgba($purple, 0.05);
        }
      }
      .col {
        width: calc(75% / 4);
        padding: 4px 8px;
        display: flex;
        align-items: center;
        position: relative;
        &.sort {
          cursor: pointer;
        }
        &.desc {
          .icon {
            transform: rotate(180deg)
          }
        }
        .icon {
          position: absolute;
          @include flex-center;
          color: $text-light;
          left: -10px;
        }
        &:last-child {
          width: 25%;
        }
        p {
          margin-bottom: 0px;
          font-size: 14px;
          &.role {
            border: 1px solid #EAE8E3;
            padding: 3px 10px;
            border-radius: 30px;
            font-size: 12px;
          }
        }
        .tags {
          display: flex;
          p {
            font-size: 12px;
            margin: 0px;
            padding: 4px 8px;
            border-radius: 7px;
            margin-right: 6px;
          }
        }
      }
      &.label {
        border-bottom: 1px solid #EAE8E3;
        .col {
          p {
            font-size: 12px;
            color: $text-light;
          }
        }
      }
    }
    .list {
      overflow-y: auto;
      max-height: calc(100vh - 200px);
    }
  }
}