@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.mobile {
  height: 100%;
  @media screen and (min-width: $lg-screen) {
    display: none;
  }
  .filters {
    padding: 20px 20px;
    .btns {
      display: flex;
      justify-content: space-between;
    }
    .btn {
      @include btn;
    }
    .toggle {
      height: 43px;
      width: 43px;
      border-radius: 10px;
      background-color: transparent;
      border: 1px solid #EAE8E3;
      @include flex-center;
      cursor: pointer;
    }
    .panel {
      padding-top: 20px;
      border-bottom: 1px solid #EAE8E3;
      .filters-row {
        display: flex;
        .filter {
          margin: 5px 10px 5px 0px;
        }
      }
    }
  }
  .list {
    overflow-y: auto;
    padding: 0px 20px 20px 20px;
    .card {
      min-height: 83px;
      display: block;
      text-decoration: none;
      padding: 15px;
      border-radius: 12px;
      background: $white;
      margin-bottom: 20px;
      box-shadow: 0px 5.453px 32.717px 0px rgba(178, 178, 178, 0.20);
      h4 {
        margin-bottom: 10px;
        font-size: 16px;
        span {
          text-transform: uppercase;
        }
      }
      .row {
        display: flex;
        justify-content: space-between;
        .role {
          border: 1px solid #EAE8E3;
          padding: 3px 10px;
          border-radius: 30px;
          font-size: 12px;
        }
        .tags {
          display: flex;
          p {
            font-size: 12px;
            margin: 0px;
            padding: 4px 8px;
            border-radius: 7px;
            margin-right: 6px;
          }
          &:last-child {
            p {
              margin-right: 0px;
              margin-left: 6px;
            } 
          }
        }
      }
    }
  }
}