@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.row {
  display: flex;
  max-width: 600px;
}

.row2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.companyFields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $margin-sm;
  max-width: 600px;
}

.containerField {
  @include containerField;
  width: 100%;
  &.cover {
    width: 100%;
    @media screen and (min-width: $lg-screen) {
      width: 50%;
    }
    h4 {
      @include bold;
    }
  }
  &.type {
    display: flex;
    align-items: flex-end;
    >div:first-child {
      width: 100%;
    }
    >button {
      margin-left: $margin-md;
    }
  }
}

.profiles {
  width: 70%;
}

.picture {
  background-color:rgba($black, 0.02);
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: dashed 1px rgba($black, 0.3);
  color: $primary-color;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  &.media {
    border: 1px solid  #EAE8E3;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  svg {
    font-size: 24px;
    margin-right: 10px;
  }
  p {
    color: $primary-color;
    font-family: "NunitoSans-Bold";
    margin: 0px;
  }
}


.tags {
  display: flex;
  >div {
    margin-bottom: $margin-xs;
    margin-right: $margin-xs;
  }
}

