@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    width: 100%;
    overflow-y: auto;
    @include globalPage;
    @media screen and (min-width: $lg-screen) {
      padding: 0px 30px;
    }
    header {
      padding: 10px 0px 0px 0px;
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
    }
    .toggle {
      margin: auto;
      display: flex;
      justify-content: center;
      border-radius: 40px;
      background: #EAECFE;
      width: calc(100% - 40px);
      max-width: 300px;
      margin-top: 20px;
      button {
        width: 50%;
        display: block;
        @include bold;
        border: none;
        background-color: transparent;
        padding: 8px 10px;
        color:  #4C549E;
        @include transition;
        &.active {
          background-color: $white;
          border-radius: 40px;
          box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.20);
        }
      }
      @media screen and (min-width: $lg-screen) {
        display: none;
      } 
    }
    .name {
      margin-top: 10px;
      h1 {
        @include bold;
        font-size: 24px;
        margin: 0px;
      }
      p {
        color: $text-light;
      }
      @media screen and (min-width: $lg-screen) {
        margin-top: 0px;
      }
    }
    
    .content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      .col {
        top: 0px;
        left: 0px;
        position: absolute;
        width: 100%;
        padding: 10px 20px;
        @include transition;
        transform: translateY(0px);
        opacity: 1;
        &:last-child {
          padding-top: 35px;
        }
        &:not(.active) {
          transform: translateY(100px);
          opacity: 0;
        }
      }
      @media screen and (min-width: $lg-screen) {
        flex-direction: row;
        .col {
          position: relative;
          width: calc(50% - 10px);
          padding: 0px 0px;
          &:not(.active) {
            transform: translateY(0px);
            opacity: 1;
          }
          &:last-child {
            padding-top: 55px;
          }
        }
      }
      .picture {
        @include profilePicture;
        @media screen and (min-width: $lg-screen) {
          width: 236px;
          height: 236px;
        }
      }
      .box {
        width: 100%;
        height: 200px;
        border-radius: 11px;
        background: #FFF;
        margin-bottom: 20px;
        box-shadow: 0px 14px 64px rgba(24, 39, 75, 0.04);
      }
    }
  }
  .btn {
    @include btn;
  }
}