@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.navbar {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  img {
    margin: 20px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    .profile {
      text-decoration: none;
      background-color: #C2C8FF;
      width: 45px;
      height: 45px;
      border-radius: 25px;
      @include flex-center;
      font-size: 16px;
      color: $purple;
      text-transform: uppercase;
      img {
        width: 100%;
        border-radius: 25px;
      }
    }
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
    }
  }
  @media screen and (min-width: $lg-screen) {
    width: 140px;
    height: 100%;
    position: absolute;
    display: flex;
    left: 0px;
  }
}

.links {
  margin-top: 10px;
  a {
    text-decoration: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    &:hover {
      .icon {
        .bg {
          opacity: 0.26;
        }
      }
    }
    .icon {
      position: relative;
      width: 46px;
      height: 46px;
      border-radius: 6px;
      margin-bottom: 6px;
      @include flex-center;
      .bg {
        @include transition;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0.09;
        border-radius: 6px;
        &.active {
          opacity: 0.26;
        }
      }
    }
    p {
      font-size: 14px;
    }
  }
}

.menu {
  @include transition;
  position: fixed;
  background-color: $white;
  width: 100%;

  height: 100svh;
  overflow-y: auto;
  padding: 20px;
  transform: translateX(-100vw);
  z-index: 10;
  @media screen and (min-width: $sm-screen) {
    height: 100vh;
  }
  &.open {
    transform: translateX(0);
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      @include bold;
      font-size: 24px;
      line-height: 24px;
      color: $purple;
      margin: 0px;
    }
    button {
      padding: 0px;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }
  .links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: auto;
    margin-top: 30px;
    @media screen and (min-width: $sm-screen) {
      max-width: 400px;
    }
    a {
      background-color: $white;
      margin-bottom: 0px;
      padding-top: 12px;
      border-radius: 12px;
      box-shadow: 0px 5.398px 32.388px 0px rgba(178, 178, 178, 0.20);
    }
  }
  .profile {
    background-color: $white;
    margin: auto;
    margin-top: 20px;
    border-radius: 12px;
    padding: 15px;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    text-decoration: none;
    box-shadow: 0px 5.398px 32.388px 0px rgba(178, 178, 178, 0.20);
    @media screen and (min-width: $sm-screen) {
      max-width: 400px;
    }
    p {
      margin: 0px;
    }
    img {
      margin-right: 10px;
    }
    .circle {
      text-decoration: none;
      margin-right: 10px;
      background-color: #C2C8FF;
      width: 45px;
      height: 45px;
      border-radius: 25px;
      @include flex-center;
      font-size: 16px;
      text-transform: uppercase;
      color: $purple;
    }
  }
  .logout {
    position: absolute;
    width: calc(100% - 40px);
    bottom: 20px;
    display: flex;
    justify-content: center;
    margin: 20px auto;
    border: none;
    background-color: transparent;
    p {
      margin: 0px 0px 0px 10px;
      color: $purple;
    }
  }
  @media screen and (min-width: $lg-screen) {
    display: none;
  }
}