@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.modalPlants {
  width: 493px;
  h3 {
    font-size: 16px;
    @include bold;
  }
  .field {
    margin-bottom: 10px;
  }
  .actions {
    margin-top: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    p {
      margin: 0px 10px;
      color: rgba($black, 0.5)
    }
    button {
      @include btn;
      padding: 0px 15px;
      height: 45px;
      padding: 0px 14px;
      display: flex;
      align-items: center;
      &.disabled {
        pointer-events: none;
      }
    }
    .loader {
      transform: translate(-4px, 2px);
      svg {
        margin: 0px;
      }
    }
  }
}