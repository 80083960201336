@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  @media screen and (min-width: $lg-screen) {
    background-image: url('./images/bg.svg');
    background-repeat: no-repeat;
    background-position: top 20% right 0px;
  }
  @media screen and (min-width: $xl-screen) {
    background-position: top 40% right 60px;
  }
  .page {
    @include globalPage;
    padding: 20px 20px 90px 20px;
    overflow-y: auto;
    header {
      padding: 10px 0px 20px 0px;
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        .role {
          width: 200px;
          margin-right: 10px;
        }
      }
    }
    .content {
  
    }
    h2 {
      @include bold;
      font-size: 14px;
      line-height: 14px;
    }
    .mobile {
      position: fixed;
      bottom: 20px;
      width: calc(100% - 40px);
      .submit {
        width: 100%;
      }
      @media screen and (min-width: $lg-screen) {
        display: none;
      }
    }
  }
  .submit {
    @include btn;
  }
}