@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.article {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    @include globalPage;
    overflow-y: auto;
    header {
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
    }
    .content {
      padding: 20px;
      @media only screen and (min-width: $lg-screen) {
        padding: 0px 30px 50px 30px;
        max-width: 1024px;
        margin: 0px auto;
      }
      .title {
        p {
          color: $text-light;
          margin-bottom: 6px;
        }
        h1 {
          @include bold;
          font-size: 22px;
          line-height: normal;
          margin-bottom: 10px;
        }
      }
      .mobile {
        padding-bottom: 20px;
        border-bottom: 1px solid $border-grey;
        @media only screen and (min-width: $lg-screen) {
          display: none;
        }
      }
      .row {
        width: 100%;
        @media only screen and (min-width: $lg-screen) {
          display: flex;
          justify-content: space-between;
        }
        .col {
          width: 100%;
          margin-top: 20px;
          h2 {
            margin-top: 20px;
            @include bold;
            font-size: 17px;
          }
          .description {
            margin-top: 20px;
            margin-bottom: 20px;
            color: $text-light;
          }
          .text {
            p {
              margin: 0px;
              color: $text-light;
            }
            a {
              color: $primary-color;
            }
          }
          .media {
            width: 100%;
            overflow: hidden;
            margin: 20px 0px;
            img, video {
              width: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
          }
          &:last-child {
            display: none;
            label {
              @include bold;
            }
            .tags {
              margin-bottom: 10px;
            }
          }
          @media only screen and (min-width: $lg-screen) {
            width: calc(100% - 250px);
            &:last-child {
              width: 230px;
              display: block;
            }
          }
          .cover {
            width: 100%;
            overflow: hidden;
            img {
              width: 100%;
              border-radius: 10px;
              object-fit: cover;
            }
            @media only screen and (min-width: $sm-screen) {
              height: 300px;
              img {
                height: 100%;
              }
            }
          }
        }
      }
      .tags {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (min-width: $sm-screen) {
          margin: 5px 0px;
        }
        p {
          padding: 3px 10px;
          margin: 5px 5px 5px 0px ;
          border-radius: 30px;
          font-size: 12px;
          background-color: $primary-color;
          color: $white;
        }
      }
      .creator {
        display: flex;
        align-items: center;
        margin-top: 10px;
        .picture {
          height: 44px;
          width: 44px;
          border-radius: 22px;
          background-color: rgba($primary-color, 0.3);
          margin: 0px 10px 0px 0px;
          max-width: 44px;
          text-transform: uppercase;
          @include flex-center;
          color: $primary-color;
        }
        p {
          font-size: 17px;
          @include bold;
          margin: 0px;
          color: $primary-color;
        }
        @media only screen and (min-width: $lg-screen) {
          .picture {
            height: 31px;
            width: 31px;
            max-width: 31px;
            font-size: 12px;
            margin: 0px 10px 0px 0px;
          }
          p {
            font-size: 14px;
          }          
        }
      }
    }
  }
}