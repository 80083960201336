@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.header {
  background-color: $purple;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 0px 20px;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    @include bold;
    font-size: 24px;
    line-height: 20px;
    color: $white;
    margin: 0px;
  }
  a {
    display: flex;
    align-items: center;
    color: $white;
    text-decoration: none;
    img {
      width: 25px;
      margin-right: 10px;
    }
    p {
      margin: 0px;
      @include bold;
      font-size: 18px;
      color: $white;
    }
  }
  .toggle {
    background-color: transparent;
    border: none;
    padding: 0px;
    cursor: pointer;
  }
  @media screen and (min-width: $lg-screen) {
    display: none;
  }
}