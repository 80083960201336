@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    @include globalPage;
  }
  .btn {
    @include btn;
  }
}

.learner {
  padding: 20px 20px;
  @media only screen and (min-width: $md-screen) {
    padding: 20px 30px;
  }
  @media only screen and (min-width: $lg-screen) {
    padding: 0px 30px;
  }
  .row {
    width: 100%;
    @media only screen and (min-width: $md-screen) {
      display: flex;
      justify-content: space-between;
    }
    .col {
      width: 100%;
      height: 300px;
      margin-bottom: 20px;
      @media only screen and (min-width: $md-screen) {
        width: calc(60% - 10px);
        margin-bottom: 0px;
        height: 300px;
        &:first-child {
          width: calc(40% - 10px);
        }
      }
      &:first-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 300px;
      }
      &:last-child {
        height: 200px;
        @media only screen and (min-width: $md-screen) {
          height: 300px;
        }
      }
      .counter {
        height: calc(50% - 10px);
      }
      .stats {
        position: relative;
        height: 100%;
        border-radius: 11px;
        background: $white;
        padding-bottom: 20px;
        overflow: hidden;
        .label {
          position: absolute;
          top: 20px;
          left: 20px;
        }
      }
    }
  }
}