@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.user {
  position: relative;
  width: 100%;
  height: 100vh;
  .submit {
    @include btn;
  }
  .page {
    @include globalPage;
    overflow-y: auto;
    padding-bottom: 40px;
    @media screen and (min-width: $lg-screen) {
      padding-bottom: 0px;
    }
    header {
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
      .actions {
        display: flex;
        align-items: center;
      }
    }
    .content {
      padding: 20px;
    }
    .mobile {
      position: fixed;
      bottom: 20px;
      left: 20px;
      width: calc(100% - 40px);
      .submit {
        width: 100%;
      }
      @media screen and (min-width: $lg-screen) {
        display: none;
      }
    }
  }
  .btn {
    @include btn;
  }
}