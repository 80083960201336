@import "../../../styles/_variables.scss";
@import "../../../styles/mixins";

.container {
  width: 100%;
  @media only screen and (min-width: $md-screen) {
    display: flex;
    justify-content: center;
    width: calc(100%);
  }
  @media only screen and (min-width: 1100px) {
    width: calc(100% + 100px);
  }
  &.disabled {
    pointer-events: none;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
    margin: auto;
    justify-content: space-between;
    @media only screen and (min-width: 1100px) {
      width: 340px;
    }
    .item {
      &.disabled {
        pointer-events: none;
      }
    }
    .option {
      margin: 10px 5px;
      border-radius: 8px;
      border: 1px solid #EAE8E3;
      background: #FBFAF9;
      width: 70px;
      height: 60px;
      box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.40);
      overflow: hidden;
      &.isDragging {
        border: 2px solid $primary-color;
      }
      &.empty {
        box-shadow: none;
        pointer-events: none;
      }
      @media only screen and (min-width: 1100px) {
        width: 90px;
        height: 80px;
        margin: 20px 0px;
      }
      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
    }
  }
}

.responses {
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: calc(100% - 20px);
  max-width: 400px;
  @media only screen and (min-width: 1100px) {
    width: calc(50% + 0px);
    margin: 0px;
    max-width: 100%;
  }
  .response {
    width: calc(100% - 40px);
    @media only screen and (min-width: $md-screen) {
      width: 80%;
    }
    @media only screen and (min-width: 1100px) {
      width: calc(100% - 100px);
    }
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #EAE8E3;
    background-color: $white;
    padding: 10px;
    margin-bottom: 10px;
    &.correct {
      border: 1px solid $success;
      outline: 1px solid $success;
    }
    &.wrong {
      border: 1px solid $warn;
      outline: 1px solid $warn;
    }
    .drop {
      display: flex;
      .option {
        width: 70px;
        margin-left: 10px;
        height: 60px;
        border-radius: 4px;
        border: 2px solid #EAE8E3;
        background-color: #FBFAF9;
        @include flex-center;
        overflow: hidden;
        box-shadow: 0px 3.773px 22.638px 0px rgba(178, 178, 178, 0.40);
        @media only screen and (min-width: 1100px) {
          width: 90px;
          height: 70px;
        }
        &.empty {
          border: 2px dashed #EAE8E3;
          box-shadow: none;
          &.dragging {
            border: 2px dashed $primary-color;
          }
        }
        &.hover {
          background-color: rgba(76, 84, 158, 0.20);
          border: 2px solid $primary-color;
        }
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}

.option-pictures {
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  &.row-1 {
    display: flex;
    justify-content: center;
    flex-direction: row;
    img {
      height: calc(50% - 5px);
      height: 100px;
      border-radius: 10px;
      width: calc(100%);
      @media only screen and (min-width: $md-screen) {
        height: 100px;
      }
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-2 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    img {
      height: 100px;
      border-radius: 10px;
      width: calc(50% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  &.row-3 {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 85px;
    @media only screen and (min-width: $md-screen) {
      height: 60px;
    }
    @media only screen and (min-width: $xl-screen) {
      height: 85px;
      display: flex;
    }
    img {
      height: 100%;
      border-radius: 10px;
      width: calc(33% - 5px);
      &:not(:last-child) {
        margin-bottom: 0px;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}