@import "../../styles/_mixins.scss";
@import "../../styles/_variables.scss";

.inputSearch {
  .toggle {
    height: 43px;
    width: 43px;
    border-radius: 10px;
    background-color: transparent;
    border: 1px solid #EAE8E3;
    @include flex-center;
    cursor: pointer;
    font-size: 16px;
    @include transition;
    &:hover {
      font-size: 18px;
    }
  }
}