@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.global {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
  .container {
    width: 100%;
    height: 100vh;
    background-color: #F8F8FB;
    @media screen and (min-width: $lg-screen) {
      width: calc(100% - 140px);
      position: absolute;
      right: 0px;
    }
  }
}