@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins";
.container {
  width: 80vw;
  max-width: 800px;
  h2 {
    @include bold;
    font-size: 18px;
  } 
  .btn {
    @include btn;
  }
  .filters {
    display: flex;
    align-items: center;
    > div {
      margin-right: 10px;
    }
    .search {
      min-width: 30%;
      margin-right: 15px;
      input {
        width: 100%;
        border-radius: 10px;
        padding: 8px 10px;
        border: 1px solid #EAE8E3;
        font-size: 14px;
      }
    }
  }
  .list {
    padding-top: 8px;
    height: 30vh;
    overflow-y: auto;
    margin-bottom: 30px;
  }
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 36px;
    &.label {
      margin-top: 20px;
      border-bottom: 1px solid $border-grey;
      p {
        padding-bottom: 5px;
        color: $text-light;
      }
    }
    .col {
      width: calc(100% - 15px);
      @media screen and (min-width: $lg-screen) {
        width: calc(50% - 15px);
      }
      &:last-child {
        display: none;
        @media screen and (min-width: $lg-screen) {
          display: flex;
        }
      }
      .check {
        @include transition;
        background-color: rgba($text-light, 0.2);
        width: 20px;
        height: 20px;
        border-radius: 3px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgba($text-light, 0.4);
        }
        svg {
          color: $primary-color;
        }
      }
      p {
        margin: 0px;
      }
      &:first-child {
        width: 30px;
      }
    }
  }
  .action {
    display: flex;
    justify-content: flex-end;
  }
}