@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  width: 100%;
  height: 100%;
  @include flex-center;
  flex-direction: column;
  p {
    @include poppinsBold;
    font-size: 18px;
  }
  a {
    @include btn;
    background-color: $purple;
    border: 1px solid $purple;
    @include poppinsBold;
    text-decoration: none;
  }
}