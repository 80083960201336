@import "../../styles/variables";
@import "../../styles/mixins";


.edit {
  position: relative;
  width: 100%;
  height: 100vh;
  .page {
    width: 100%;
    overflow-y: auto;
    @include globalPage;
    @media screen and (min-width: $lg-screen) {
      padding: 0px 30px;
    }
    header {
      padding: 10px 0px 0px 0px;
      a {
        display: flex;
        text-decoration: none;
        img {
          margin-right: 8px;
        }
        p {
          margin: 0px;
          color: $text-light;
        }
      }
    }
  }
}